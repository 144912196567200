<script>
/**
 * Materials Data component
 */
import { authComputed, materialsMethods } from "@/state/helpers";
export default {
  props: ["materials", "busy", "loaderStatus"],
  components: {
  },
  data() {
    return {
      material: {},
      materialsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != 'USER' && this.loggedIn.user.role != 'OPERATOR') {
        return [
          { key: "type", sortable: false, label: this.$t("dataTable.type.text"), class: "tdClass" },
          { key: "quantity", sortable: false, label: this.$t("dataTable.quantity.text"), class: "tdClass" },
          { key: "model", sortable: false, label: this.$t("dataTable.model.text"), class: "tdClass" },
          { key: "user", sortable: false, label: this.$t("dataTable.user.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.region.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "centre", sortable: false, label: this.$t("dataTable.centre.text"), class: "tdClass" },
          { key: "state", sortable: false, label: this.$t("dataTable.status.text"), class: "tdClass" },
          { key: "isUrgent", sortable: false, label: this.$t("dataTable.gravity.text"), class: "tdClass" },
          { key: "description", sortable: false, label: this.$t("dataTable.description.text"), class: "tdClass" },
          /* { key: "actions", sortable: false, label: this.$t("dataTable.tools.text"), class: "tdClass" }, */
        ];
      } else {
        return [
          { key: "type", sortable: false, label: this.$t("dataTable.type.text"), class: "tdClass" },
          { key: "quantity", sortable: false, label: this.$t("dataTable.quantity.text"), class: "tdClass" },
          { key: "model", sortable: false, label: this.$t("dataTable.model.text"), class: "tdClass" },
          { key: "user", sortable: false, label: this.$t("dataTable.user.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.region.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "centre", sortable: false, label: this.$t("dataTable.centre.text"), class: "tdClass" },
          { key: "state", sortable: false, label: this.$t("dataTable.status.text"), class: "tdClass" },
          { key: "isUrgent", sortable: false, label: this.$t("dataTable.gravity.text"), class: "tdClass" },
          { key: "description", sortable: false, label: this.$t("dataTable.description.text"), class: "tdClass" },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...materialsMethods,
    /*showModalEdit() {
      this.$bvModal.show("edit-material-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-material-modal");
    },
    edit(material) {
      this.updateMaterial(material)
        .then((material) => {
          this.makeToast(
            "Update material",
            material.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update material", error, "danger");
        });
    }, 
    passMaterial(type, material) {
      if (type == "edit") {
        this.material = material;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t('modals.askValidDelete.text'),
            text: this.$t('modals.cantRevert.text'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delMaterial(material.id)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The material has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },*/
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      //this.$router.push({name:'alert', params: { serial: record.serial }})
      console.log(record);
    },
  },
  watch: {
    materials(newVal) {
      this.materialsList =[...newVal.map(obj=>{
        return {
          ...obj,
          centre: obj.centre.name,
          region: obj.region.name,
          province: obj.province.name,
          user: obj.user.fullName
        }
      })];
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 350px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        :empty-text="$t('dataTable.noMaterials.text')"
        :items="materialsList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{$t('dataTable.loading.text')}}</strong>
          </div>
        </template>
        <template v-slot:cell(state)="row">
          <div
            class="badge font-size-12 text-center"
            :class="{
              'badge-soft-warning': `${row.item.state}` === 'PENDING',
              'badge-soft-success': `${row.item.state}` === 'ACCEPTED',
              'badge-soft-danger': `${row.item.state}` === 'REJECTED',
            }"
          >
            {{ row.item.state }}
          </div>
        </template>
        <template v-slot:cell(isUrgent)="row">
          <div
            class="font-size-14 text-center font-weight-bold"
          >
            <i 
              class="ri-checkbox-blank-circle-fill"
              :class="row.item.isUrgent === true ? 'text-danger' : 'text-success'"
            ></i>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>