<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import MaterialsData from "./materials-data";

import {
  layoutComputed,
  authComputed,
  materialsMethods,
  materialsComputed,
  provincesMethods,
  provincesComputed,
  regionsMethods,
  regionsComputed,
} from "@/state/helpers";
/**
 * Materials component
 */
export default {
  page: {
    title: "Materials",
    meta: [{ name: "Materials" }],
  },
  components: {
    Layout,
    PageHeader,
    MaterialsData,
  },
  mounted() {
    this.retrieveMaterials({}).then(async ()=>{
      await this.retrieveRegions();
      await this.retrieveProvinces();
    });
  },
  data() {
    return {
      title: "titles.materials.text",
      busy: false,
      loaderDashboard: false,

      currentPage: 1,
      perPage: 10,

      materialsData: [],

      provincesData: [],
      regionsFilterData: [{ value: null, text: "--All--" }],
      provincesFilterData: [{ value: null, text: "--All--" }],
      materialsFilter: {
        regions: null,
        provinces: null,
        year: null,
        month: null,
        type: null
      },
      regions: [null],
      provinces: [null],
      yearOptions: [
        {value: null, text: 'select...'}, 
        {value: 2022, text: '2022'},
        {value: 2023, text: '2023'},
        {value: 2024, text: '2024'}
      ],
      monthOptions: [
        {value: null, text: 'select...'}, 
        {value: 1, text: '1'},
        {value: 2, text: '2'},
        {value: 3, text: '3'},
        {value: 4, text: '4'},
        {value: 5, text: '5'},
        {value: 6, text: '6'},
        {value: 7, text: '7'},
        {value: 8, text: '8'},
        {value: 9, text: '9'},
        {value: 10, text: '10'},
        {value: 11, text: '11'},
        {value: 12, text: '12'}
      ]
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...materialsComputed,
    ...provincesComputed,
    ...regionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.materials.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...materialsMethods,
    ...provincesMethods,
    ...regionsMethods,

    retrieveMaterials({
      page, 
      perPage,
      regions,
      provinces,
      year,
      month,
      type
    }) {
        this.busy = true;
        return this.getMaterialsBy({ 
          page: page ? page : this.currentPage,
          limit: perPage ? perPage : this.perPage,
          regions: regions ? regions : this.materialsFilter.regions,
          provinces: provinces ? provinces : this.materialsFilter.provinces,
          year: year ? year : this.materialsFilter.year,
          month: month ? month : this.materialsFilter.month,
          type: type ? type : this.materialsFilter.type
        }).then(() => {
          this.busy = false;
        });
      
    },
    retrieveRegions() {
      if (this.getRegions.results)
        return this.chargeRegions(this.getRegions.results);
      else
        return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces(regions) {
      if (this.getProvinces.results)
        return this.chargeProvinces(this.getProvinces.results, regions);
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(regions) {
      this.provincesFilterData = [{ value: null, text: "--All--" }];
      let pData = this.getProvinces.results;
      pData.map((obj) => {
        if (regions.includes(obj.region))
          this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    filter() {
      this.currentPage = 1;
      const filtredMatsFilter = this.cleanObject(this.materialsFilter)
      this.retrieveMaterials({ ...filtredMatsFilter, page: 1 }).then(() => {
        this.hideFilter();
      });
    },
    clear() {
      this.materialsFilter = {
        regions: null,
        provinces: null,
      };
      this.regions = [null];
      this.provinces = [null];
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-material-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-material-modal");
    },
    add(material) {
      this.addMaterial(material)
        .then((material) => {
          this.makeToast(
            "Add material",
            material.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add material", error, "danger");
        });
    },
    openFilter(){
      this.$bvModal.show("filter-modal");
    },
    hideFilter(){
      this.$bvModal.hide("filter-modal");
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveMaterials({});
    },
  },
  watch: {
    getMaterials(newVal) {
      this.materialsData = newVal.result;
    },
    getRegions(newVal) {
      this.chargeRegions(newVal.results);
    },
    regions(newVal) {
      let lenRegions = newVal.length;

      if (!newVal[lenRegions - 1] && lenRegions > 1) {
        this.clear()
      } else if (newVal[lenRegions - 1] && newVal.includes(null)) {
        var filteredRegions = this.regions.filter(function (item) {
          return item != null;
        });
        this.regions = filteredRegions;
      } else if (newVal[lenRegions - 1] && !newVal.includes(null)) {
        this.materialsFilter.regions = newVal;
      } else if (newVal.length == 0) {
        this.clear()
      }
      this.chargeProvinces(newVal);
    },
    provinces(newVal) {
      let lenProvinces = newVal.length;

      if (!newVal[lenProvinces - 1] && lenProvinces > 1) {
        this.provinces = [null];
        this.materialsFilter.provinces = null;
      } else if (newVal[lenProvinces - 1] && newVal.includes(null)) {
        var filteredProvinces = this.provinces.filter(function (item) {
          return item != null;
        });
        this.provinces = filteredProvinces;
      } else if (newVal[lenProvinces - 1] && !newVal.includes(null)) {
        this.materialsFilter.provinces = newVal;
      } else if (newVal.length == 0) {
        this.provinces = [null];
        this.materialsFilter.provinces = null;
      }
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
      <div class="text-right">
        <b-button type="button" class="mr-2" variant="light" @click="openFilter"
          ><i class="ri-filter-3-line"></i>
        </b-button>
        <b-modal 
          id="filter-modal"
          ref="filter-modal"
          hide-footer
          size="md"
          :title="this.$t('modals.filterMaterial.text')"
        >
          <div class="row">
            <div class="col-12" data-app>
              <label for="regionSh">{{ $t("modals.region.text") }}</label>
              <v-select
                id="regionSh"
                v-model="regions"
                :items="regionsFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12" data-app>
              <label for="provinceSh">{{ $t("modals.province.text") }}</label>
              <v-select
                id="provinceSh"
                v-model="provinces"
                :items="provincesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12" data-app>
              <label for="yearSh">{{ $t("modals.year.text") }}</label>
              <b-form-select id="yearSh" v-model="materialsFilter.year" :options="yearOptions"></b-form-select>
            </div>
            <div class="col-12" data-app>
              <label for="monthSh">{{ $t("modals.month.text") }}</label>
              <b-form-select id="monthSh" v-model="materialsFilter.month" :options="monthOptions"></b-form-select>
            </div>
            <div class="col-12" data-app>
              <label for="typeSh">{{ $t("modals.type.text") }}</label>
              <b-form-input id="typeSh" v-model="materialsFilter.type" :placeholder="$t('modals.type.text')"></b-form-input>
            </div>
            <div class="col-12 text-center">
              <b-button type="button" variant="light" @click="filter"
                >{{$t("buttons.filter.text")}}
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
    <div class="row">
      <div class="col-12">
        <MaterialsData v-bind:materials="materialsData" :busy="busy"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getMaterials.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>